
export default {
  head() {
    return this.$metadata(this.story)
  },
  data() {
    return {
      story: {}
    }
  },
  methods: {
    async loadexpstory(id) {
      const expstory = await this.$storyblok.getStory(
        `global/expansion-stores/${id}`
      )
      this.story = expstory
    },
    updateMyLocation(slug) {
      if (slug === undefined || slug === null || slug === 'null') {
        const storeId = this.$cookiz.get('boj_loc_id')
        console.log(`Store ID::: ${storeId}`)
        console.log(`Stores::: ${this.$store.state.allExpandedLocations}`)
        const isExpansion = this.$store.state.allExpandedLocations.find(
          (s) => s.slug === storeId
        )
        console.log(`is expansion market store ${!!isExpansion}`)
        if (isExpansion) this.loadexpstory(storeId)
      }
    }
  },
  mounted() {
    this.$storybridge.on(['input', 'published', 'change'], (event) => {
      if (event.action === 'input') {
        if (event.story.id === this.story.id) {
          this.story.content = event.story.content
        }
      } else {
        window.location.reload()
      }
    })
  },
  async validate({ $storyblok, store, params, error }) {
    let slug = params.slug
    if (slug === undefined || slug === null || slug === 'null') {
      slug = 'home'
    }
    return await $storyblok.getStory(slug)
  },
  async asyncData({ $storyblok, store, params, $cookiz, error }) {
    let slug = params.slug

    if (slug === undefined || slug === null || slug === 'null') slug = 'home'

    try {
      const story = await $storyblok.getStory(slug)
      return {
        story
      }
    } catch (error) {
      console.error(error)
    }
  }
}
